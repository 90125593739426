import React, { useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import "./Birthday.css";

function BirthdayCarousel({birthday_record}) {
  const { resultData } = useContext(EmplContext);

  // const birthday_record = resultData.birthday_record || [];
  // console.log("birthday_record === ", birthday_record);
  const settings = {
    arrows: false,
    dots: false,
    infinite: true, // Allow infinite scrolling even for one item
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay even for one record
    autoplaySpeed: 2000, // Adjust the speed as needed
    slickPlay: true,
  };

  return (
    <>
      {birthday_record.length === 1 ? (
        <div className="slick-container w-3/4 m-auto">
          <Slider {...settings}>
            <div>
              <div className="slick-item">
                <img
                  src={`data:image/jpeg;base64,${atob(birthday_record[0][0])}`}
                  // alt={`Document ${index + 1}`}
                  className="img-fluid rounded-circle"
                  style={{ height: 100 }}
                />
                <p
                  style={{
                    fontSize: 25,
                    fontWeight: 500,
                    color: "#44444491",
                    textAlign: "center",
                    marginTop: 22,
                    marginBottom: 1,
                  }}
                ></p>
                <p
                  style={{
                    fontFamily: '"Caveat Brush", cursive',
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  {birthday_record[0][1]}
                  <br />
                  {birthday_record[0][2]}
                  <br />
                  {birthday_record[0][3]}
                </p>
              </div>
            </div>
            <div>
              <div className="slick-item">
                <img
                  src={`data:image/jpeg;base64,${atob(birthday_record[0][0])}`}
                  // alt={`Document ${index + 1}`}
                  className="img-fluid rounded-circle"
                  style={{ height: 100 }}
                />
                <p
                  style={{
                    fontSize: 25,
                    fontWeight: 500,
                    color: "#44444491",
                    textAlign: "center",
                    marginTop: 22,
                    marginBottom: 1,
                  }}
                ></p>
                <p
                  style={{
                    fontFamily: '"Caveat Brush", cursive',
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  {birthday_record[0][1]}
                  <br />
                  {birthday_record[0][2]}
                  <br />
                  {birthday_record[0][3]}
                </p>
              </div>
            </div>
          </Slider>
        </div>
      ) : (
        <div className="slick-container w-3/4 m-auto">
          <Slider {...settings}>
            {birthday_record.map((birthday_rec, index) => (
              <div key={index}>
                <div className="slick-item">
                  <img
                    src={`data:image/jpeg;base64,${atob(birthday_rec[0])}`}
                    alt={`Document ${index + 1}`}
                    className="img-fluid rounded-circle"
                    style={{ height: 100 }}
                  />
                  <p
                    style={{
                      fontSize: 25,
                      fontWeight: 500,
                      color: "#44444491",
                      textAlign: "center",
                      marginTop: 22,
                      marginBottom: 1,
                    }}
                  ></p>
                  <p
                    style={{
                      fontFamily: '"Caveat Brush", cursive',
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontSize: 20,
                      textAlign: "center",
                    }}
                  >
                    {birthday_rec[1]}
                    <br />
                    {birthday_rec[2]}
                    <br />
                    {birthday_rec[3]}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
}

export default BirthdayCarousel;
