import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import configData from "../../../config.json";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Password = () => {
  const [newAddress, setNewAddress] = useState({
    email: "",
    passcode: "",
    new_pin: "",
    confirm_new_pin: "",
  });
  const [emailExists, setEmailExists] = useState(true);
  const navigate = useNavigate();
  const [emailVerified, setEmailVerified] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpFromServer, setOtpFromServer] = useState("");
  const [timer, setTimer] = useState(60);
  const [otpSent, setOtpSent] = useState(false);
  // eslint-disable-next-line
  const [otpExpired, setOtpExpired] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [cshowPassword, setCShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickCShowPassword = () => setCShowPassword((show) => !show);

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0 && !otpVerified) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      setOtpExpired(true);
    }
    return () => clearInterval(interval);
  }, [otpSent, timer, otpVerified]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleReChange = (e) => {
    setNewAddress({
      ...newAddress,
      [e.target.name]: e.target.value,
    });
  };

  const submitNewPassword = async (e) => {
    e.preventDefault();
    try {
      const url = `${configData.SERVER_URL}/forget_password`;
      const secretKey = configData.SECRET_Key;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: newAddress.email, secretKey }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const resultData = JSON.parse(data.result);
      // console.log(resultData);
      if (resultData.success === false) {
        // alert(resultData.message, 'data of the forgot pin');
        setEmailExists(false);
      }
      if (resultData.success === true) {
        Swal.fire({
          icon: "success",
          title: "Otp Sent! Please check your mail",
        });
        setEmailExists(true); // Email exists, set to true
        setEmailVerified(true);
        setOtpFromServer(resultData.emailOtp);
        setOtpSent(true);
        setTimer(60); // Reset the timer to 60 seconds
      }else{
        console.log("result ===", resultData);
      }
    } catch (error) {
      console.error("Failed to add address:", error);
    }
  };

  const validateOtp = () => {
    if (newAddress.passcode === otpFromServer) {
      setOtpVerified(true);
      setTimer(0); // Stop the timer when OTP is correct
      Swal.fire({
        icon: "success",
        title: "OTP Verified",
        text: "Please reset your pin",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "OTP Invalid",
      });
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    if (newAddress.new_pin !== newAddress.confirm_new_pin) {
      Swal.fire({
        icon: "warning",
        text: "Pins do not match",
      });
      return;
    }
    const url = `${configData.SERVER_URL}/update_password`;
    const secretKey = configData.SECRET_Key;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: newAddress.email,
        password: newAddress.new_pin,
        confirmPassword: newAddress.confirm_new_pin,
        secretKey,
      }),
    });

    const responseData = await response.json();
    const resultData = JSON.parse(responseData.result);
    // console.log(responseData);

    if (resultData.success === true) {
      // console.log("pin updated");
      Swal.fire({
        icon: "success",
        title: "Pin Updated",
      }).then(() => {
        navigate("/");
      });
    } else {
      Swal.fire({
        icon: "error",
        title: resultData.message,
      });
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  return (
    <>
      <div className="hrms_form">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="">
                <img src="Vector.png" className="img-fluid" alt="vector img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 hrms_right_lform_sd">
              <div className="hrms_form_cont">
                <h5 style={{ marginTop: "22px", marginLeft: "13px" }}>
                  Reset Pin
                </h5>
                <Form onSubmit={submitNewPassword}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    value={newAddress.email}
                    onChange={handleReChange}
                    autoComplete="Recovery Mail"
                    fullWidth
                    disabled={otpVerified}
                    required
                    error={!emailExists}
                    helperText={!emailExists ? "Email does not exist" : ""}
                    style={{
                      height: "55px",
                      borderColor: emailVerified ? "green" : undefined,
                      "&:hover": {
                        borderColor: emailVerified ? "green" : undefined,
                      },
                      "&:focus": {
                        borderColor: emailVerified ? "green" : undefined,
                      },
                    }}
                  />
                  <>
                    {emailVerified && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <TextField
                            autoFocus
                            margin="dense"
                            id="passcode"
                            name="passcode"
                            label="Enter OTP"
                            type="text"
                            value={newAddress.passcode}
                            onChange={handleReChange}
                            autoComplete="Enter OTP"
                            fullWidth
                            required
                            disabled={otpVerified}
                          />
                          {!otpVerified && (
                            <Button
                              variant="contained"
                              onClick={(e) => submitNewPassword(e)}
                              disabled={timer > 0}
                            >
                              Resend
                            </Button>
                          )}
                        </div>
                        {!otpVerified && <span>{formatTime(timer)}</span>}
                      </>
                    )}
                    {otpVerified && (
                      <>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="new_pin"
                          name="new_pin"
                          label="New Pin"
                          // type="pin"
                          type={showPassword ? "text" : "password"}
                          value={newAddress.new_pin}
                          onChange={handleReChange}
                          autoComplete="New pin"
                          fullWidth
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle pin visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          autoFocus
                          margin="dense"
                          id="confirm_new_pin"
                          name="confirm_new_pin"
                          label="Confirm New Pin"
                        //   type="pin"
                          type={cshowPassword ? "text" : "password"}
                          value={newAddress.confirm_new_pin}
                          onChange={handleReChange}
                          autoComplete="New Pin Again"
                          fullWidth
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle pin visibility"
                                  onClick={handleClickCShowPassword}
                                  edge="end"
                                >
                                  {cshowPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </>
                    )}
                  </>

                  <div className="OptionsContainer"></div>

                  {!emailVerified && (
                    <Button
                      variant="contained"
                      type="submit"
                      className="material-ui-button mt-4"
                      sx={{ marginTop: "7px" }}
                    >
                      Send OTP
                    </Button>
                  )}
                  {emailVerified && !otpVerified && timer > 0 && (
                    <Button
                      variant="contained"
                      className="material-ui-button mt-4"
                      sx={{ marginTop: "7px" }}
                      onClick={validateOtp}
                    >
                      Validate OTP
                    </Button>
                  )}
                  {otpVerified && (
                    <Button
                      variant="contained"
                      className="material-ui-button mt-4"
                      sx={{ marginTop: "7px" }}
                      onClick={(e) => updatePassword(e)}
                    >
                      Reset
                    </Button>
                  )}
                  <br />
                  <br />
                </Form>
              </div>
              <div className="second" />
              <div className="third" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Password;
