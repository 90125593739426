import React, { useState, useContext, useEffect } from "react";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../../src/config.json";
import { ColorContext } from "../../ColorContext";
import LoadingComponent from "../loading_component";

function Holiday() {
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [Holidays, setHolidays] = useState([]);
  const { textColor } = useContext(ColorContext);
  const [load, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const secretKey = configData.SECRET_Key;
        const requestData = { employee_id, secretKey };
        const response = await fetch(`${configData.SERVER_URL}/myholidays`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });
        const data = await response.json();
        const result = JSON.parse(data.result);
        // console.log('result', result);
        if (result.success === true) {
          setHolidays(result.holidays_record);
        }else{
          console.log("result ===", result);
        }
      } catch (error) {
        console.error("Error:", error);
      }finally{
        setLoading(false)
      }
    };

    fetchCompanyDetails();
  }, [employee_id]);
  return (
    <>
      {load ? (
        <div className="main-panel">
          <LoadingComponent />
        </div>
      ) : (
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="ep_content">
              <div className="row">
                <div className="col-md-12">
                  <div className="al_holi">
                    <h2 className="al_holi_tl">Holiday</h2>
                  </div>
                  {/* <div className="das-filter">
                <div className="das-filtitle">
                  <img
                    src="filter.jpg"
                    style={{ height: 12, marginTop: "-4px" }}
                    alt="filter icon"
                  />{" "}
                  Filter
                </div>
              </div> */}
                  <div className="">
                    <div className="atable">
                      <table id="basicTable" className="basic-table">
                        <thead>
                          <tr
                            role="row"
                            style={{
                              backgroundColor: textColor,
                              color: "#FFF",
                            }}
                          >
                            <th className="center">
                              <span> Date </span>
                            </th>
                            <th className="center">
                              <span> Holiday </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Holidays.length > 0 ? (
                            Holidays.map(
                              (holiday, index) =>
                                holiday != null && (
                                  <tr className="holiday_table_row" key={index}>
                                    <td className="">{holiday[0]}</td>
                                    <td className="">{holiday[1]}</td>
                                  </tr>
                                )
                            )
                          ) : (
                            <tr className="holiday_table_row">
                              <td
                                className=""
                                style={{ textAlign: "center" }}
                                colSpan={2}
                              >
                                <h3>No Upcoming Holiday</h3>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      ;
    </>
  );
}

export default Holiday;
