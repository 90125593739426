import configData from "../../../config.json";

// Function to fetch data from API
const fetchManagerDetails = async (employee_id, secretKey) => {
  const requestData = { employee_id, secretKey };

  try {
    const response = await fetch(`${configData.SERVER_URL}/managerdetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    const result = JSON.parse(data.result);
    // console.log("result===", result);
    if (result.success) {
      // console.log("type of result.all_leaves_list",typeof(result.all_leaves_list))
      return result;
    } else{
      console.log("result ===", result);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const CustomerService = {
  async getData(employee_id, secretKey) {
    return fetchManagerDetails(employee_id, secretKey);
  },


  async getCustomersMedium(employee_id, secretKey) {
    const data = await this.getData(employee_id, secretKey);
    return data;
  },
};
