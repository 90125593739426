import React from 'react'

function HrDashboard_loading() {
  return (
    <>
      <div className="main-item">
        {/* <div className="css-dom" /> */}
        <div className="shared-dom asset-header">
          <div className="sub-rect pure-background"></div>
          <div className="w-75">
            <div className="animated-background ">
              <div className="background-masker btn-divide-left" />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-evenly mt-4">
          <div className="w-25">
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
          </div>

          <div className="w-25">
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
          </div>

          <div className="w-25 ">
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
          </div>
        </div>

        <div className="shared-dom asset-header">
          <div className="sub-rect pure-background"></div>
          <div className="w-75">
            <div className="animated-background ">
              <div className="background-masker btn-divide-left" />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-evenly mt-4">
          <div className="w-25">
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
          </div>

          <div className="w-25">
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
          </div>

          <div className="w-25 ">
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HrDashboard_loading