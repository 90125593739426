import React from "react";
import "./AttendanceLoading.css";
import TableLoading from "../HomePage/LeaveTable/TableLoading";

function AttendanceLoading() {
  return (
    <>
      <div className="main-item">
        <div className="shared-dom">
          <div className="sub-rect pure-background" />
          <div className="sub-rect pure-background" />
          <div className="sub-rect pure-background" />
          <div className="sub-rect pure-background" />
          <div className="sub-rect pure-background" />
        </div>

        {/* <div className="static-background">
          <div className="background-masker btn-divide-left" />
        </div> */}
        <div className="animated-background ">
          <div className="background-masker btn-divide-left" />
        </div>

        <div className="animated-background mt-4">
          <div className="background-masker btn-divide-left" />
        </div>

        <div className="d-flex justify-content-evenly mt-4">
          <div className="w-25">
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
          </div>

          <div className="w-25">
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
          </div>

          <div className="w-25 ">
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
            <div className="post">
              {/* <div className="avatar" /> */}
              <div className="line" />
              <div className="line" />
            </div>
          </div>
        
          
        </div>

        {/* <div className="css-dom" /> */}
      </div>
    </>
  );
}

export default AttendanceLoading;
