import { useState } from 'react';

const useSharedState = () => {
  const [stateA, setStateA] = useState('');
  const [assetsFormData, setAssetsFormData] = useState({
    name: "",
    requestType: "Replacements",
    employeeCode: "",
    asset: "",
    assetCode: "",
    noOfAsset: "",
    reason: "",
    photos: null,
  });
  const [isAssetsModalOpen, setIsAssetsModalOpen] = useState(false);

  const handleStateChange = (newState) => {
    setIsAssetsModalOpen(newState);
    // console.log("stateA===================",isAssetsModalOpen)
  };

  return {
    isAssetsModalOpen,
    setIsAssetsModalOpen,
    assetsFormData,
    setAssetsFormData,
    handleStateChange
  };
};

export default useSharedState;
