import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import "./HrDashboard.css";
import EventCalendars from "./EventCalendar/EventCalendar";
import { Gauge } from "@mui/x-charts/Gauge";
import configData from "../../../src/config.json";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import Swal from "sweetalert2";
import LoadingComponent from "../loading_component";
import HrDashboard_loading from "./HrDashboard_loading";

function Hrdashboard() {
  // const [emplinfo, setEmplinfo] = useState({
  //   company_image: null,
  // });
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const annouceRef = useRef(null);
  const [dailyAttendance, setDailyAttendance] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [leaves, setLeaves] = useState({
    today_leaves: [],
    next_week_leaves: [],
    all_leaves_request: [],
    announcements: [],
  });
  const [selectedRequest, setSelectedRequest] = useState(null);
  const handleRequestClick = (leave) => {
    setSelectedRequest(leave);
  };
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isattendance, setIsAttendance] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    startDate: "",
    endDate: "",
    description: "",
    is_announcement: false,
  });
  // const [events, setEvents] = useState({
  //   title: '',
  //   start: '',
  //   end: '',
  // });
  const [events, setEvents] = useState([]);
  const [result, setResult] = useState({
    jobs_count: "",
    new_application_count: "",
    feedback_application_count: "",
    jobs_list: [],
    applicants_list: [],
  });
  const [load, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };
  const toggleAttVisibility = () => {
    setIsAttendance(!isattendance);
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (new Date(formData.startDate) > new Date(formData.endDate)) {
        Swal.fire({
          icon: "error",
          title: "Start date must be less than End Date",
        });
        return;
      }
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey, formData };
      const response = await fetch(`${configData.SERVER_URL}/announcement`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result);
      if (result.success === true) {
      } else {
        Swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setFormData({
        title: "",
        startDate: "",
        endDate: "",
        description: "",
        is_announcement: false,
      });
      setIsFormVisible(false);
      fetchHrDetails();
    }
  };

  const fetchHrDetails = useCallback(async () => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/hrdetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result);
      if (result.success === true) {
        // setEmplinfo({
        //   company_image: result.company_image_url,
        // });
        setDailyAttendance(result.daily_attendance_count);
        setLeaves((prev) => ({
          ...prev,
          today_leaves: result.today_leaves_list,
          next_week_leaves: result.next_week_leaves_list,
          all_leaves_request: result.all_leaves_request_list,
          announcements: result.announcement_list,
        }));
        setEvents(result.event_list);
        setAttendance(result.daily_attendance_list);
        setResult({
          jobs_count: result.jobs_count,
          new_application_count: result.new_application_count,
          feedback_application_count: result.feedback_application_count,
          jobs_list: result.jobs_list,
          applicants_list: result.applicants_list,
        });
        setLoading(false);
      }else{
        console.log("result ===", result);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [employee_id]);

  useEffect(() => {
    fetchHrDetails();
  }, [fetchHrDetails]);

  const handleLeaveAction = async (leave, action) => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey, leave, action };
      const response = await fetch(`${configData.SERVER_URL}/handleleave`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result);
      if (result.success === true) {
        Swal.fire({
          icon: "success",
          title: result.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSelectedRequest(null);
      fetchHrDetails();
    }
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (annouceRef.current && !annouceRef.current.contains(event.target)) {
        setIsFormVisible(false);
      }
    },
    [setIsFormVisible]
  );

  useEffect(() => {
    if (isFormVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFormVisible, handleClickOutside]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        // Handle Esc key press logic here
        // console.log("Esc key pressed");
        // Example: close a modal or perform another action
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <>
      <div className="logo-hr-dashboard">
        <img
          src="company_logo.png"
          alt="Profile Pic"
          className="img-xs brand-logo-image "
        />
      </div>
      {load ? (
        <div className="">
          <HrDashboard_loading />
        </div>
      ) : (
        <div className="content-wrapper hr-dashboard-container">
          <div className="ep_content">
            <div className="row">
              <div className="col-md-12">
                <div className="al_holi">
                  <h2 className="al_holi_tl">Welcome, {resultData.name}</h2>
                  <p className="al_holi_stl">{resultData.department}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-9">
                <div className="ev-section">
                  <div className="ev_head">
                    <div className="ev_tile">
                      <i className="fas fa-calendar-week ev-icon" /> Upcoming
                      Events
                    </div>
                    <div className="ev_brcrm">Today</div>
                  </div>
                  <div className="ev_body">
                    <div className="ev_table">
                      <EventCalendars
                        events={events}
                        fetchHrDetails={fetchHrDetails}
                      />
                    </div>
                    <div className="ev_status">
                      <ul>
                        <li>
                          <div className="ev_st1">
                            <div className="ev_st_icon approved">
                              <img src="event.png" style={{}} alt="" />
                            </div>
                            <div className="text"> Event </div>
                          </div>
                        </li>
                        <li>
                          <div className="ev_st1">
                            <div className="ev_st_icon rejected">
                              <img src="brday.png" style={{}} alt="" />{" "}
                            </div>
                            <div className="text"> Birthday </div>
                          </div>
                        </li>
                        <li>
                          <div className="ev_st1">
                            <div className="ev_st_icon pending">
                              {" "}
                              <img src="ann.png" style={{}} alt="" />
                            </div>
                            <div className="text"> Anniversary </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="ev-section">
                  <div className="ev_head">
                    <div className="ev_tile">
                      <i className="fas fa-calendar-week ev-icon" /> Recruitment
                    </div>
                    {/* <div
                    className="ev_ricon"
                    style={{ color: "#008cee", fontSize: 13 }}
                  >
                    View All <i className="fas fa-angle-right" />
                  </div> */}
                  </div>
                  <div className="ev_body">
                    <div className="ev_divide">
                      <div className="ev_divleft">
                        <div className="ev_emp_info">
                          <div className="ev_emp_infod">
                            <div className="ev_emp_pr">
                              <i
                                className="fas fa-file"
                                style={{ color: "#ff98008c" }}
                              />{" "}
                              <span className="emp_count">
                                {" "}
                                {result.jobs_count || ""}{" "}
                              </span>{" "}
                            </div>
                            <p className="ev_emp_des"> Job opening</p>
                          </div>
                          <div className="ev_emp_infod">
                            <div className="ev_emp_pr">
                              <i
                                className="fas fa-file"
                                style={{ color: "#6221e7c7" }}
                              />{" "}
                              <span className="emp_count">
                                {result.new_application_count || ""}
                              </span>{" "}
                            </div>
                            <p className="ev_emp_des"> New candidates</p>
                          </div>
                          <div className="ev_emp_infod">
                            <div className="ev_emp_pr">
                              <i
                                className="fas fa-file"
                                style={{ color: "#2196f3d4" }}
                              />{" "}
                              <span className="emp_count">50</span>{" "}
                            </div>
                            <p className="ev_emp_des"> Invited for interview</p>
                          </div>
                          <div className="ev_emp_infod">
                            <div className="ev_emp_pr">
                              <i
                                className="fas fa-file"
                                style={{ color: "#e91e63c4" }}
                              />{" "}
                              <span className="emp_count">
                                {result.feedback_application_count || ""}
                              </span>{" "}
                            </div>
                            <p className="ev_emp_des"> Waiting for feedbacks</p>
                          </div>
                        </div>
                        <div
                          className="ev_hrt_box"
                          style={{ maxHeight: "242px", overflow: "auto" }}
                        >
                          <table className="ev_hr_tbl">
                            <thead>
                              <tr className="ev_hr_bg">
                                <td>
                                  <span> JOB </span>
                                </td>
                                <td>
                                  <span> TOTAL CANDIDATES </span>
                                </td>
                                <td>
                                  <span> VACANCIES </span>
                                </td>
                                <td>
                                  <span> DATE EXPIRED </span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {result.jobs_list.map((jobs, index) => (
                                <tr className="" key={index}>
                                  <th>
                                    <span> {jobs[0]} </span>
                                  </th>
                                  <td className="">{jobs[1]}</td>
                                  <td>{jobs[2]}</td>
                                  <td className="">22/02/2000</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="ev_divright">
                        <div className="evProcess_head">Ongoing process</div>
                        <div className="evProcess">
                          {result.applicants_list.map((applicant, index) => (
                            <div
                              className="evProcess_steps"
                              key={index}
                              // style={{ cursor: "pointer" }}
                            >
                              <div className="evProcess_step">
                                <img src="vnn_1.jpg" alt="" />
                                <div className="">
                                  <div className="">{applicant[0]}</div>
                                  <p>{applicant[1]}</p>
                                </div>
                              </div>
                              <div className="evProcess_tag">
                                <span className="span1">{applicant[2]}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ev_request">
                  <div className="evr_left">
                    <div className="evr-section">
                      <div className="ev_head">
                        <div className="ev_tile">
                          {leaves.all_leaves_request.length !== 0 ? (
                            <>
                              <i className="fas fa-hourglass-start ev-icon" />
                              Pending approval{" "}
                            </>
                          ) : (
                            "No Requests"
                          )}
                        </div>
                        {/* <div
                        className="ev_ricon"
                        style={{ color: "#008cee", fontSize: 13 }}
                      >
                        View All <i className="fas fa-angle-right" />
                      </div> */}
                      </div>
                      <div className="ev_body">
                        <div className="ev_approve">
                          <div className="ev_approveL">
                            {leaves.all_leaves_request.length !== 0 && (
                              <>
                                <div className="evProcess_head">
                                  Ongoing process
                                </div>
                              </>
                            )}

                            <div
                              className="evProcess"
                              style={{
                                borderLeft: "none",
                                borderRight: "1px solid #dfdfdf",
                                paddingLeft: 0,
                              }}
                            >
                              {leaves.all_leaves_request.map((leave, index) => (
                                <div
                                  className="evProcess_steps"
                                  key={index}
                                  onClick={() => handleRequestClick(leave)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="evProcess_step">
                                    <img
                                      src={
                                        leave[5]
                                          ? `data:image/jpeg;base64,${atob(
                                              leave[5]
                                            )}`
                                          : "vnn_1.jpg"
                                      }
                                      className="img-xs rounded-circle"
                                      alt="profile"
                                    />
                                    <div className="">
                                      <div className="">{leave[0]}</div>
                                      <p>
                                        {leave[1]} - {leave[2]}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="evProcess_tag">
                                    <span
                                      className={
                                        leave[3] === "To Approve"
                                          ? "span1"
                                          : "span2"
                                      }
                                    >
                                      {leave[3]}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="ev_approveR">
                            {leaves.all_leaves_request.length !== 0 && (
                              <>
                                <div className="evProcess_head">
                                  Ongoing process
                                </div>
                                <div className="evProcess_head">Form View</div>
                                {selectedRequest ? (
                                  <div className="formView">
                                    <div className="d-flex justify-content-between">
                                      <button
                                        className="btn btn-outline-success"
                                        onClick={() =>
                                          handleLeaveAction(
                                            selectedRequest[4],
                                            "approve"
                                          )
                                        }
                                      >
                                        Approve
                                      </button>
                                      <button
                                        className="btn btn-outline-warning"
                                        onClick={() =>
                                          handleLeaveAction(
                                            selectedRequest[4],
                                            "reject"
                                          )
                                        }
                                      >
                                        Reject
                                      </button>
                                    </div>
                                    <div>
                                      <strong>Name:</strong>{" "}
                                      {selectedRequest[0]}
                                    </div>
                                    <div>
                                      <strong>Date:</strong>{" "}
                                      {selectedRequest[1]} -{" "}
                                      {selectedRequest[2]}
                                    </div>
                                    <div>
                                      <strong>Status:</strong>{" "}
                                      {selectedRequest[3]}
                                    </div>
                                  </div>
                                ) : (
                                  <div>Select a request to view details</div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="evr_right">
                    <div className="evr-section">
                      <div className="ev_head">
                        <div className="ev_tile">
                          <i className="far fa-user ev-icon" /> Newcomers today
                        </div>
                      </div>
                      <div className="ev_body">
                        <div className="ev_ProLists">
                          <div className="ev_ProList">
                            <div className="ev_ProDetailL">
                              <img src="vnn_1.jpg" alt="" />
                            </div>
                            <div className="ev_ProDetailR">
                              <div className="">Lorem Ipsum</div>
                              <p>Lorem Ipsum</p>
                            </div>
                          </div>
                          <div className="ev_ProList">
                            <div className="ev_ProDetailL">
                              <img src="vnn_1.jpg" alt="" />
                            </div>
                            <div className="ev_ProDetailR">
                              <div className="">Lorem Ipsum</div>
                              <p>Lorem Ipsum</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div
                  className="ev-section"
                  style={{ minHeight: 400, position: "relative" }}
                >
                  <div className="ev_head">
                    <div className="ev_tile">
                      <i className="fas fa-calendar-week ev-icon" /> Company
                      news{" "}
                    </div>

                    <div
                      className="ev_ricon"
                      style={{ color: "#008cee", fontSize: 13 }}
                    >
                      <i
                        className="fa-sharp fa-solid fa-plus mx-3 pe-auto"
                        role="button"
                        onClick={toggleFormVisibility}
                      ></i>{" "}
                      View All <i className="fas fa-angle-right" />
                    </div>
                  </div>
                  {isFormVisible && (
                    <div
                      ref={annouceRef}
                      className="form-container"
                      style={{
                        position: "absolute",
                        right: "100px",
                        width: 250,
                        top: 35,
                        background: "#c7e5ff",
                        padding: "10px",
                      }}
                    >
                      <form className="annouce-form" onSubmit={handleSubmit}>
                        <div>
                          <label htmlFor="announce-title">Title</label>
                          <input
                            id="announce-title"
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="announce-check">
                            <input
                              id="announce-check"
                              type="checkbox"
                              name="is_announcement"
                              checked={formData.is_announcement}
                              onChange={handleInputChange}
                            />
                            Is Announcement
                          </label>
                        </div>
                        <div>
                          <label htmlFor="announce-start">Start Date</label>
                          <input
                            id="announce-start"
                            type="date"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="annouce-end">End Date</label>
                          <input
                            id="annouce-end"
                            type="date"
                            name="endDate"
                            value={formData.endDate}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="annouce-desp">Description</label>
                          <textarea
                            id="annouce-desp"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            required
                          ></textarea>
                        </div>
                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      </form>
                    </div>
                  )}
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        "\n                    .ev_news{\n                      display: flex;\n                          align-items: center;\n                          border-bottom: 1px solid #dfdfdf;\n                    }\n                    .ev_news-icon{\n                          padding: 20px 26px 20px 26px;\n                    }\n                    .ev_news-detail p {\n                      margin-bottom: 0;\n                      font-size: 12px;\n                      color: gray;\n                    }\n                  ",
                    }}
                  />
                  <div className="ev_body">
                    {leaves.announcements.map((event, index) => (
                      <div className="ev_news" key={index}>
                        <div className="ev_news-icon">
                          <i className="fas fa-calendar-week ev-icon" />
                        </div>
                        <div className="ev_news-detail">
                          <div>
                            {event[0]}, {event[3]}
                          </div>
                          <p>
                            <span>{event[1]}</span> <span>| {event[2]}</span>{" "}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3">
                <div className="ev-section">
                  <div className="ev_head">
                    <div
                      className="ev_tile btn btn-secondary"
                      role="button"
                      onClick={toggleAttVisibility}
                    >
                      Today's Attendance
                    </div>
                    <div className="ev_ricon">
                      <i className="fas fa-ellipsis-h" />
                    </div>
                  </div>
                  <div
                    className={`atten-table ${isattendance ? "visible" : ""}`}
                    // style={{
                    //   maxHeight: isattendance ? "250px" : "0",
                    // }}
                  >
                    <table id="basicTable" className="basic-table">
                      <thead>
                        <tr role="row" style={{ background: "#d2900d" }}>
                          <th className="center">
                            <span> Name </span>
                            <span />
                          </th>
                          <th className="center">
                            <span> Check-in </span>
                            <span />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {attendance.map((attend, idx) => (
                          <tr key={idx} className="">
                            <td>{attend[0]}</td>
                            <td>{attend[1]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="ev_body">
                    <Gauge
                      value={
                        dailyAttendance[1] !== undefined
                          ? dailyAttendance[1]
                          : 5
                      }
                      valueMax={
                        dailyAttendance[0] !== undefined
                          ? dailyAttendance[0]
                          : 20
                      }
                      startAngle={-110}
                      endAngle={110}
                      sx={{
                        fontSize: 40,
                        transform: "translate(0px, 0px)",
                        height: "200px",
                        width: "287px",
                      }}
                      text={({ value, valueMax }) => `${value} / ${valueMax}`}
                    />
                    <div className="d-flex justify-content-center">
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          background: "#1976d2",
                        }}
                        className="mx-2"
                      >
                        {" "}
                      </div>
                      Active
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          background: "rgba(0, 0, 0, 0.12)",
                        }}
                        className="mx-2"
                      >
                        {" "}
                      </div>
                      Total
                    </div>
                  </div>
                </div>
                {(leaves.today_leaves.length !== 0 ||
                  leaves.next_week_leaves.length !== 0) && (
                  <div className="ev-section">
                    <div className="ev_head">
                      <div className="ev_tile">
                        <i className="fas fa-user ev-icon" /> Who's on leave
                      </div>
                      <div
                        className="ev_ricon"
                        style={{ color: "#008cee", fontSize: 13 }}
                      >
                        {/* View All <i className="fas fa-angle-right" /> */}
                      </div>
                    </div>

                    <div className="ev_body">
                      <div className="ev_ProLists">
                        <div className="ev_ProDivider pt-0">Today</div>
                        {leaves.today_leaves.map((leave, index) => (
                          <div className="ev_ProList" key={index}>
                            <div className="ev_ProDetailL">
                              <img
                                // src="vnn_1.jpg"
                                src={
                                  leave[3]
                                    ? `data:image/jpeg;base64,${atob(leave[3])}`
                                    : "vnn_1.jpg"
                                }
                                className="img-xs rounded-circle"
                                alt="profile"
                              />
                            </div>
                            <div className="ev_ProDetailR">
                              <div>{leave[0]}</div>
                              <p>
                                {leave[1]} - {leave[2]}
                              </p>
                            </div>
                          </div>
                        ))}
                        <div className="ev_ProDivider">Next Week</div>
                        {leaves.next_week_leaves.map((leave, index) => (
                          <div className="ev_ProList" key={index}>
                            <div className="ev_ProDetailL">
                              <img
                                src={
                                  leave[3]
                                    ? `data:image/jpeg;base64,${atob(leave[3])}`
                                    : "vnn_1.jpg"
                                }
                                className="img-xs rounded-circle"
                                alt="profile"
                              />
                            </div>
                            <div className="ev_ProDetailR">
                              <div>{leave[0]}</div>
                              <p>
                                {leave[1]} - {leave[2]}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className="ev-section">
                  <div className="ev_head">
                    <div className="ev_tile">
                      <i className="far fa-user ev-icon" />
                      Interview
                    </div>
                  </div>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        "\n                    .ev_task_d {\n                      display: flex;\n                          gap: 12px;\n                              background: #80808012;\n                        padding: 11px;\n                        border-radius: 5px;\n                            margin: 12px 0;\n                    }\n                    .ev_task_pro {\n                          padding-left: 10px;\n                          border-left: 2px solid black;\n\n                    }\n                    .ev_task_ttl{\n                      font-size: 14px;\n                    }\n                    .ev_task_pro ul {\n                      padding: 0;\n                      list-style: none;\n                    }\n                    .ev_task_pro li{\n                          color: gray;\n                          font-size: 13px;\n                    }\n                    .ev_task_pro i {\n                      color: black;\n                      font-size: 12px;\n                      padding-right: 5px;\n                    }\n                  ",
                    }}
                  />
                  <div className="ev_body">
                    <div className="ev_task">
                      <div className="ev_task_d">
                        <div className="ev_task_tsk">
                          <div className="ev_task_ttl">
                            <b>09.00</b>
                          </div>
                          <p>1st Task</p>
                        </div>
                        <div className="ev_task_pro">
                          <div className="ev_task_ttl">
                            <b>Sales Executive</b>
                          </div>
                          <ul>
                            <li>
                              <i className="far fa-user ev-icon" /> John Davis
                            </li>
                            <li>
                              <i className="far fa-user ev-icon" /> 60 mins
                            </li>
                            <li>
                              <i className="far fa-user ev-icon" /> Room 01
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="ev_task_d">
                        <div className="ev_task_tsk">
                          <div className="ev_task_ttl">
                            <b>09.00</b>
                          </div>
                          <p>1st Task</p>
                        </div>
                        <div className="ev_task_pro">
                          <div className="ev_task_ttl">
                            <b>Sales Executive</b>
                          </div>
                          <ul>
                            <li>
                              <i className="far fa-user ev-icon" /> John Davis
                            </li>
                            <li>
                              <i className="far fa-user ev-icon" /> 60 mins
                            </li>
                            <li>
                              <i className="far fa-user ev-icon" /> Room 01
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="ev_task_d">
                        <div className="ev_task_tsk">
                          <div className="ev_task_ttl">
                            <b>09.00</b>
                          </div>
                          <p>1st Task</p>
                        </div>
                        <div className="ev_task_pro">
                          <div className="ev_task_ttl">
                            <b>Sales Executive</b>
                          </div>
                          <ul>
                            <li>
                              <i className="far fa-user ev-icon" /> John Davis
                            </li>
                            <li>
                              <i className="far fa-user ev-icon" /> 60 mins
                            </li>
                            <li>
                              <i className="far fa-user ev-icon" /> Room 01
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="ev_task_d">
                        <div className="ev_task_tsk">
                          <div className="ev_task_ttl">
                            <b>09.00</b>
                          </div>
                          <p>1st Task</p>
                        </div>
                        <div className="ev_task_pro">
                          <div className="ev_task_ttl">
                            <b>Sales Executive</b>
                          </div>
                          <ul>
                            <li>
                              <i className="far fa-user ev-icon" /> John Davis
                            </li>
                            <li>
                              <i className="far fa-user ev-icon" /> 60 mins
                            </li>
                            <li>
                              <i className="far fa-user ev-icon" /> Room 01
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Hrdashboard;
