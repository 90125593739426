import React from "react";
import "./Base64Viewer.css";

const Base64Viewer = ({ base64Code, fileType, fileName }) => {
  // Function to detect file type based on passed fileType prop
  const getFileType = (fileType) => {
    if (fileType === "image") {
      return "image";
    } else if (fileType === "pdf") {
      return "pdf";
    }
    return null;
  };

  // Function to trigger download of the file
  const handleDownload = () => {
    const dataUrl =
      fileType === "image"
        ? `data:image/jpeg;base64,${atob(base64Code)}`
        : `data:application/pdf;base64,${atob(base64Code)}`;

    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = `download.${fileType}`;
    link.click();
  };

  const file_Type = getFileType(fileType);

  return (
    <div className="base64-viewer">
      {/* {file_Type === "image" && (
        <div>
          <img
            src={`data:image/jpeg;base64,${atob(base64Code)}`}
            alt="Image Preview"
            style={{
              width: "100px",
              height: "auto",
            }}
          />
        </div>
      )}
      {file_Type === "pdf" && (
        <div>
          <iframe
            src={`data:application/pdf;base64,${atob(base64Code)}`}
            height="250"
            width="100%"
            title="PDF Preview"
          ></iframe>
        </div>
      )}
      {!file_Type && <p>Unsupported file type</p>} */}

      {/* Download Button */}
      <button onClick={handleDownload} className="pdf-download-link mt-2">
        <i className="fa-regular fa-file-pdf" />{" "}
        {fileName
          ? fileName.length > 8
            ? `${fileName.substring(0, 8)}...`.toUpperCase()
            : fileName.toUpperCase()
          : "File"}
      </button>
    </div>
  );
};

export default Base64Viewer;
