import React, { useContext, useState, useEffect } from "react";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../config.json";
import LoadingComponent from "../loading_component";
import Swal from "sweetalert2";
import { ColorContext } from "../../ColorContext";
import Asset_loading from "../Asset_Page/Asset_loading";
import './MyExpenses.css'

const MyExpenses = ({sharedState}) => {
  const { isAssetsModalOpen,setIsAssetsModalOpen, assetsFormData ,setAssetsFormData ,handleStateChange } = sharedState;

  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [expenses, setExpenses] = useState([]);
  const [load, setLoading] = useState(false);
  const {textColor} = useContext(ColorContext);

  useEffect(() => {
    setLoading(true);
  }, []);

  const fetchData = async () => {
      // Direct execution without SweetAlert2
      try {
        const secretKey = configData.SECRET_Key;
        const requestData = { employee_id, secretKey };
        const response = await fetch(`${configData.SERVER_URL}/myexpenses`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });
        const data = await response.json();
        const result = JSON.parse(data.result);
        // console.log("result===",result)
        if (result.success === true) {
          setExpenses(result.my_expense_list);
          // setRequests(result.my_requests_list);
          // setLoading(false);
        }else{
          console.log("result ===", result);
        }
      } catch (error) {
        console.error("Error:", error);
      }finally{

        setLoading(false)
      }
  };

  useEffect(() => {

    fetchData();
  }, [employee_id]);

  

  return (
    <>
      {load ? (
        <div className="main-panel">
          <Asset_loading/>
        </div>
      ) : (
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="ep_content">
              <div className="row">
                <div className="col-md-12">
                  <div className="al_holi">
                    <h2 className="al_holi_tl">My Expenses</h2>
                  </div>
                  <div className="">
                    <div className="atable">
                      <table id="basicTable" className="basic-table">
                        <thead className="all-tables-head">
                          <tr role="row" style={{ backgroundColor: textColor }}>
                            {/* <th className="center">
                              <span> S.No </span>
                              <span />
                            </th> */}

                            <th className="center">
                              <span> Expense Date </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Type </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Description </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Total in Currency </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Status </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Payment State </span>
                              <span />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {expenses.map((expense, idx) => (
                            <tr key={idx} className="" >
                              {/* <td>{idx + 1}</td> */}
                              <td>{expense.date}</td>
                              <td>{expense.type}</td>
                              <td>{expense.name}</td>
                              <td><i className="fa-solid fa-indian-rupee-sign"/> {expense.total_amount}</td>
                              <td>{expense.state}</td>
                              <td>{expense.payment_state}</td>

                              {/* <td>
                                <button className="btn btn-primary" onClick={() => handleStateChangeAsset(asset)}>
                                  Raise
                                </button>
                              </td> */}
                            </tr>
                          ))}
                        
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <div className="al_holi mt-5">
                    <h2 className="al_holi_tl"> Asset Request </h2>
                  </div>
                  <div className="">
                    <div className="atable">
                      <table id="basicTable" className="basic-table">
                        <thead className="all-tables-head">
                          <tr role="row" style={{ backgroundColor: textColor }}>
                            <th className="center">
                              <span> S.No </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Asset Type </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Request Type </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Asset Code </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Status </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Date </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Delete Request</span>
                              <span />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {requests.map((request, idx) => (
                            <tr key={idx} className="">
                              <td>{idx + 1}</td>
                              <td>{request.asset_allocation_id}</td>
                              <td>{request.reason}</td>
                              <td>{request.seq_no}</td>
                              <td>{request.status}</td>
                              <td>{request.last_modify_date}</td>
                              <td>
                                <button className="btn btn-danger" onClick={() => deleteAsset(request.rec_id)}>
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyExpenses;
