import React, { useContext, useState, useEffect } from "react";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../config.json";
import LoadingComponent from "../loading_component";
import Swal from "sweetalert2";
import { ColorContext } from "../../ColorContext";
import Asset_loading from "./Asset_loading";


const MyAsset = ({sharedState}) => {
  const { isAssetsModalOpen,setIsAssetsModalOpen, assetsFormData ,setAssetsFormData ,handleStateChange } = sharedState;

  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [requests, setRequests] = useState([]);
  const [assets, setAssets] = useState([]);
  const [load, setLoading] = useState(false);
  const {textColor} = useContext(ColorContext);
  
  const handleStateChangeAsset = (asset)=>{
    // console.log("asset========",asset)
    setIsAssetsModalOpen(true)
    setAssetsFormData({
      requestType: "Replacements",
      employeeCode: "",
      asset: asset.asset_id,
      assetCode: asset.seq_no,
      noOfAsset: "",
      reason: "",
      photos: null,
    });

  }
  useEffect(() => {
    setLoading(true);
  }, []);

  const fetchData = async (index) => {
    if (index === false) {
      // Direct execution without SweetAlert2
      try {
        const secretKey = configData.SECRET_Key;
        const rec_id = index;
        const requestData = { employee_id, secretKey, rec_id };
        const response = await fetch(`${configData.SERVER_URL}/myassets`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });
        const data = await response.json();
        const result = JSON.parse(data.result);
        if (result.success === true) {
          setAssets(result.my_assests_list);
          setRequests(result.my_requests_list);
          setLoading(false);
        } else{
          console.log("result ===", result);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      // Confirmation with SweetAlert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const secretKey = configData.SECRET_Key;
            const rec_id = index;
            const requestData = { employee_id, secretKey, rec_id };
            const response = await fetch(`${configData.SERVER_URL}/myassets`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestData),
            });
            const data = await response.json();
            const result = JSON.parse(data.result);
            if (result.success === true) {
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
              });
              setAssets(result.my_assests_list);
              setRequests(result.my_requests_list);
              setLoading(false);
            } else{
              console.log("result ===", result);
            }
          } catch (error) {
            console.error("Error:", error);
          }
        }
      });
    }
  };
  useEffect(() => {

    fetchData(false);
  }, [employee_id]);

  const deleteAsset = (index) => {
    fetchData(index);
    // setRequests((prevAssets) => prevAssets.filter((_, i) => i !== index));
  };

  return (
    <>
      {load ? (
        <div className="main-panel">
          <Asset_loading/>
        </div>
      ) : (
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="ep_content">
              <div className="row">
                <div className="col-md-12">
                  <div className="al_holi">
                    <h2 className="al_holi_tl">My Asset</h2>
                  </div>
                  <div className="">
                    <div className="atable">
                      <table id="basicTable" className="basic-table">
                        <thead className="all-tables-head">
                          <tr role="row" style={{ backgroundColor: textColor }}>
                            <th className="center">
                              <span> S.No </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Asset Type </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Asset Code </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Status </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Replace </span>
                              <span />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {assets.map((asset, idx) => (
                            <tr key={idx} className="" >
                              <td>{idx + 1}</td>
                              <td>{asset.asset_allocation_id}</td>
                              <td>{asset.seq_no}</td>
                              <td>{asset.status}</td>
                              <td>
                                <button className="btn btn-primary" onClick={() => handleStateChangeAsset(asset)}>
                                  Raise
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="al_holi mt-5">
                    <h2 className="al_holi_tl"> Asset Request </h2>
                  </div>
                  <div className="">
                    <div className="atable">
                      <table id="basicTable" className="basic-table">
                        <thead className="all-tables-head">
                          <tr role="row" style={{ backgroundColor: textColor }}>
                            <th className="center">
                              <span> S.No </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Asset Type </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Request Type </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Asset Code </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Status </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Date </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Delete Request</span>
                              <span />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {requests.map((request, idx) => (
                            <tr key={idx} className="">
                              <td>{idx + 1}</td>
                              <td>{request.asset_allocation_id}</td>
                              <td>{request.reason}</td>
                              <td>{request.seq_no}</td>
                              <td>{request.status}</td>
                              <td>{request.last_modify_date}</td>
                              <td>
                                <button className="btn btn-danger" onClick={() => deleteAsset(request.rec_id)}>
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyAsset;
