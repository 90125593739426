import React, { useContext, useState, useEffect } from "react";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../config.json";
import LoadingComponent from "../loading_component";
import Swal from "sweetalert2";
import { ColorContext } from "../../ColorContext";
import Asset_loading from "../Asset_Page/Asset_loading";

const Document = ({ sharedState }) => {
  //   const { isAssetsModalOpen,setIsAssetsModalOpen, assetsFormData ,setAssetsFormData ,handleStateChange } = sharedState;

  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [docs, setDocs] = useState([]);
  const [load, setLoading] = useState(true);
  const { textColor } = useContext(ColorContext);

  //   const handleStateChangeAsset = (asset)=>{
  //     // console.log("asset========",asset)
  //     setIsAssetsModalOpen(true)
  //     setAssetsFormData({
  //       requestType: "Replacements",
  //       employeeCode: "",
  //       asset: asset.asset_id,
  //       assetCode: asset.seq_no,
  //       noOfAsset: "",
  //       reason: "",
  //       photos: null,
  //     });

  //   }
  //   useEffect(() => {
  //     setLoading(true);
  //   }, []);

  const fetchData = async () => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/documents`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result===", result);
      if (result.success === true) {
        setDocs(result.document_records);
      }else{
        console.log("result ===", result);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [employee_id]);

  //   const deleteAsset = (index) => {
  //     fetchData(index);
  //     // setRequests((prevAssets) => prevAssets.filter((_, i) => i !== index));
  //   };

  const handleDownload = (type, index) => {
    const doc = docs[index];
    if (!doc) return;

    if (type === "image") {
      const link = document.createElement("a");
      link.href = `data:image/jpeg;base64,${atob(doc[1])}`;
      link.download = `image_${index}.jpeg`;
      link.click();
    } else if (type === "pdf") {
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${atob(doc[1])}`;
      link.download = `document_${index}.pdf`;
      link.click();
    }
  };

  return (
    <>
      {load ? (
        <div className="main-panel">
          <Asset_loading />
        </div>
      ) : (
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="ep_content">
              <div className="row">
                <div className="col-md-12">
                  <div className="al_holi">
                    <h2 className="al_holi_tl">Docs</h2>
                  </div>
                  <div className="">
                    <div className="atable">
                      <table id="basicTable" className="basic-table">
                        <thead className="all-tables-head">
                          <tr role="row" style={{ backgroundColor: textColor }}>
                            <th className="center">
                              <span> S No </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Name </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Document </span>
                              <span />
                            </th>
                            {/* <th className="center">
                              <span> Asset Code </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Status </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Replace </span>
                              <span />
                            </th> */}
                          </tr>
                        </thead>
                        {/* <tbody>
                          {docs.map((doc, idx) => (
                            <tr key={idx} className="">
                              <td>{idx + 1}</td>
                              <td>{doc[0]}</td>
                              <td>
                                {atob(doc[1]).charAt(0) === "/" ? (
                                  <>
                                    <img
                                      src={doc[1] ? `data:image/jpeg;base64,${atob(
                                        doc[1]
                                      )}` : "vnn_1.jpg"}
                                      alt={`Document ${idx + 1}`}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <iframe
                                      src={`data:application/pdf;base64,${atob(
                                        doc[1]
                                      )}`}
                                      title={`Document ${idx + 1}`}
                                      height="250"
                                    ></iframe>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody> */}
                        <tbody>
                          {docs.map((doc, idx) => {
                            const base64Data = doc[1];

                            // Ensure base64Data is available
                            if (!base64Data) {
                              return (
                                <tr key={idx}>
                                  <td>{idx + 1}</td>
                                  <td>{doc[0]}</td>
                                  <td>Unsupported Format</td>
                                </tr>
                              );
                            }

                            // Try-catch to handle any potential decoding errors
                            try {
                              const decodedData = atob(base64Data); // Decode the base64 string

                              // Check if the decoded data is an image by looking at its first character
                              // console.log("decodedData === ", decodedData);
                              const isImage = decodedData.charAt(0) === "i";
                              const isPdf = decodedData.slice(0, 4) === "%PDF";

                              return (
                                <tr key={idx}>
                                  <td>{idx + 1}</td>
                                  <td>{doc[0]}</td>
                                  <td>
                                    {isImage ? (
                                      <div className="d-flex flex-column align-items-center">
                                        <img
                                          src={`data:image/jpeg;base64,${atob(
                                            base64Data
                                          )}`}
                                          alt={`Document ${idx + 1}`}
                                          style={{
                                            width: "100px",
                                            height: "auto",
                                          }}
                                        />

                                        <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            handleDownload("image", idx)
                                          }
                                        >
                                          Download Image
                                        </button>
                                      </div>
                                    ) : !isImage ? (
                                      <>
                                        <div className="d-flex flex-column align-items-center">
                                          <iframe
                                            src={`data:application/pdf;base64,${atob(
                                              base64Data
                                            )}`}
                                            title={`Document ${idx + 1}`}
                                            height="250"
                                            width="100%"
                                          ></iframe>

                                          <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                              handleDownload("pdf", idx)
                                            }
                                          >
                                            Download PDF
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      "Unsupported Format"
                                    )}
                                  </td>
                                </tr>
                              );
                            } catch (error) {
                              return (
                                <tr key={idx}>
                                  <td>{idx + 1}</td>
                                  <td>{doc[0]}</td>
                                  <td>Error decoding data</td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <div className="al_holi mt-5">
                    <h2 className="al_holi_tl"> Asset Request </h2>
                  </div>
                  <div className="">
                    <div className="atable">
                      <table id="basicTable" className="basic-table">
                        <thead className="all-tables-head">
                          <tr role="row" style={{ backgroundColor: textColor }}>
                            <th className="center">
                              <span> S.No </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Asset Type </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Request Type </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Asset Code </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Status </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Date </span>
                              <span />
                            </th>
                            <th className="center">
                              <span> Delete Request</span>
                              <span />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {requests.map((request, idx) => (
                            <tr key={idx} className="">
                              <td>{idx + 1}</td>
                              <td>{request.asset_allocation_id}</td>
                              <td>{request.reason}</td>
                              <td>{request.seq_no}</td>
                              <td>{request.status}</td>
                              <td>{request.last_modify_date}</td>
                              <td>
                                <button className="btn btn-danger" onClick={() => deleteAsset(request.rec_id)}>
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Document;
