import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import React, { useState, useContext } from "react";
import "./Calendar.css";
import { Link } from "react-router-dom";
import { EmplContext } from "../../Login_Page/Login_Page/LoginPage";

const Calender = () => {
  const [date, setDate] = useState(new Date());

  const handleDateChange = (date) => {
    setDate(date);
  };

  const { resultData } = useContext(EmplContext);
  let absentDates = [];
  let presentDates = [];
  let autoLeaveDates = [];
  let rejectedautoLeaveDates = [];
  let appliedLeaveDate = [];
  let approvedLeaveDate = [];
  let rejectedLeaveDate = [];
  let holidayLeaveDate = [];
  let todayLeaveDate = [];

  // Check if resultData and resultData.calender_yearly are defined
  
  if (resultData && resultData.calender_yearly) {
    resultData.calender_yearly.forEach((item) => {
      if (item[1] === "present") {
        presentDates.push(item[0]);
      }
      if (item[1] === "absent") {
        absentDates.push(item[0]);
      }
      if (item[1] === "autoleave") {
        autoLeaveDates.push(item[0]);
      }
      if (item[1] === "autoleavereject") {
        rejectedautoLeaveDates.push(item[0]);
      }
       // if (item[1] === "approved") {
      if (item[1].startsWith("approved") ) {
        approvedLeaveDate.push(item[0]);
      }
      // if (item[1] === "refused") {
      if (item[1].startsWith("refused") ) {
        rejectedLeaveDate.push(item[0]);
      }
      // if (item[1] === "pending") {
      if (item[1].startsWith("pending") ) {
        appliedLeaveDate.push(item[0]);
      }
      if (item[1] === "holiday") {
        holidayLeaveDate.push(item[0]);
      }
      if (item[1].includes("today-leave") ) {
        // console.log("item",item[1],item[0])
        todayLeaveDate.push(item[0]);
      }
    });
  }
  // console.log("resultData.calender_yearly===",resultData.calender_yearly)
  const getTileClassName = ({ date }) => {
    // Format the current date to match the format of status dates
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1 <= 9
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }-${date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()}`;
    

    if (absentDates.includes(formattedDate)) {
      return "absent";
    }
    if (presentDates.includes(formattedDate)) {
      return "present";
    }
    if (autoLeaveDates.includes(formattedDate)) {
      return "autoleave";
    }
    if (rejectedautoLeaveDates.includes(formattedDate)) {
      return "autoleavereject";
    }
    if (appliedLeaveDate.includes(formattedDate)) {
      if (todayLeaveDate.includes(formattedDate)) {
        return " applied today-leave";
      }
      return "applied";
    }
    if (approvedLeaveDate.includes(formattedDate)) {
      if (todayLeaveDate.includes(formattedDate)) {
        return "approved today-leave";
      }
      return "approved";
    }
    if (rejectedLeaveDate.includes(formattedDate)) {
      if (todayLeaveDate.includes(formattedDate)) {
        return "rejected today-leave";
      }
      return "rejected";
    }
    if (holidayLeaveDate.includes(formattedDate)) {
      return "holiday";
    }
    

    return "";
  };

  // console.log("most_recent_holiday", resultData.most_recent_holiday);
  // console.log("calender_yearly", resultData.calender_yearly);
  return (
    <div className="ep_calender">
      <div className="cal_body">
        <Calendar
          value={date}
          onChange={handleDateChange}
          calendarType="gregory"
          next2Label={null}
          prev2Label={null}
          tileClassName={({ date }) => getTileClassName({ date })}
          minDetail="year"
        />
      </div>
      <div
        className="cal_footer"
        style={{
          display:
            resultData.most_recent_holiday &&
            resultData.most_recent_holiday[0][0]
              ? "flex"
              : "none",
        }}
      >
        <div className="cal_foot_left">
          <Link to="/holidays" className="cal_foot_link">
            All Holidays
          </Link>
        </div>
        {/* <div className="cal_foot_right">
          {resultData.most_recent_holiday &&
          resultData.most_recent_holiday[0][0] ? (
            <React.Fragment>
              <span className="cal_foot_text">
                {resultData.most_recent_holiday[0][0]}
              </span>
              <span className="cal_foot_text">
                {resultData.most_recent_holiday[0][1]}
              </span>
            </React.Fragment>
          ) : (
            <span>No Upcoming Holiday</span>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Calender;