import React from 'react';
import './TableLoading.css';

function TableLoading() {
  return (
      <>
      <div className='container-head'>

      
    <div className="w-25">
    <div className="post">
      {/* <div className="avatar" /> */}
      <div className="line" />
      <div className="line" />
    </div>
    <div className="post">
      {/* <div className="avatar" /> */}
      <div className="line" />
      <div className="line" />
    </div>
    <div className="post">
      {/* <div className="avatar" /> */}
      <div className="line" />
      <div className="line" />
    </div>
  </div>
  <div className="w-25">
    <div className="post">
      {/* <div className="avatar" /> */}
      <div className="line" />
      <div className="line" />
    </div>
    <div className="post">
      {/* <div className="avatar" /> */}
      <div className="line" />
      <div className="line" />
    </div>
    <div className="post">
      {/* <div className="avatar" /> */}
      <div className="line" />
      <div className="line" />
    </div>
  </div>
  <div className="w-25">
    <div className="post">
      {/* <div className="avatar" /> */}
      <div className="line" />
      <div className="line" />
    </div>
    <div className="post">
      {/* <div className="avatar" /> */}
      <div className="line" />
      <div className="line" />
    </div>
    <div className="post">
      {/* <div className="avatar" /> */}
      <div className="line" />
      <div className="line" />
    </div>
  </div>
  
  </div>
      </>
  
  )
}

export default TableLoading