import React, { useContext, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../config.json";
import Swal from "sweetalert2";

function Expenserequest(props) {
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;

  const [load, setLoading] = useState(false);
  const [expenserequest, setExpenserequest] = useState(false);

  const [expensesrecord, setExpensesrecord] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (expenserequest) return;

    setExpenserequest(true);

    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { expenseFormData, employee_id, secretKey };
      console.log("expenseFormData===", expenseFormData);

      const response = await fetch(
        `${configData.SERVER_URL}/expenses_request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create leave");
      }
      if (response) {
        const data = await response.json();
        // console.log("data:--", data)
        const result = JSON.parse(data.result);
        console.log("result:--", result);
        // console.log("result.success:--", result.success)

        if (result.success === true) {
          // Swal.fire({
          //   icon: "success",
          //   title: result.message,
          // });
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: result.message,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: error,
      });
    } finally {
      setExpenserequest(false);
    }
  };

  const fetchexpensesrecords = async () => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/my_expense`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result);
      if (result.success === true) {
        setExpensesrecord(result.product_records);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    fetchexpensesrecords();
  }, [employee_id]);

  const [expenseFormData, setExpenseFormData] = useState({
    productType: "",
    description: "",
    date: "",
    amount: "",
    attachment: null,
  });

  const handleExpenseFormDataChange = (e) => {
    const { name, files, value } = e.target;
    if (name === "attachment") {
      const base64DataArray = [];

      // Read each file and push its base64 representation to base64DataArray
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          base64DataArray.push(reader.result);

          // Check if all files have been processed
          if (base64DataArray.length === files.length) {
            // Update the state after all files have been read
            setExpenseFormData({ ...expenseFormData, [name]: base64DataArray });
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      setExpenseFormData({ ...expenseFormData, [name]: value });
    }
  };

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="leave-info-modal expense-request-modal"
        centered
        // size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Apply for Reimbursement
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="productType">Product Type</label>
              <select
                name="productType"
                id="productType"
                onChange={handleExpenseFormDataChange}
                value={expenseFormData.productType}
                required
              >
                <option key="empty" value="">
                  -- Select an Product --
                </option>
                {expensesrecord.map((product, idx) => (
                  <option key={idx} value={product[0]}>
                    {product[1]}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="amount">Amount</label>
              <input
                type="number"
                step="0.01" // Allows floating-point numbers with two decimal places
                min="0" // Prevents negative values
                id="amount"
                name="amount"
                placeholder="Enter amount"
                onChange={handleExpenseFormDataChange}
                value={expenseFormData.amount}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="date">Expense Date</label>
              <input
                type="Date"
                id="date"
                name="date"
                placeholder="Enter Date"
                onChange={handleExpenseFormDataChange}
                value={expenseFormData.date}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="attachment">Attachment</label>
              <input
                type="file"
                id="attachment"
                name="attachment"
                onChange={handleExpenseFormDataChange}
                accept=".pdf, image/*"
                required
                multiple
              />
            </div>

            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                name="description"
                placeholder="Enter description"
                onChange={handleExpenseFormDataChange}
                value={expenseFormData.description}
                required
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn-secondary"
              onClick={props.onHide}
            >
              Close
            </button>
            {/* <button className="btn btn-primary" type="submit" onClick={handleSubmit}> */}
            <button className="btn-primary" type="submit">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Expenserequest;
