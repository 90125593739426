import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./EventCalendar.css";
import configData from "../../../config.json";
import { EmplContext } from "../../Login_Page/Login_Page/LoginPage";
import Swal from "sweetalert2";

// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";

export default function EventCalendars({ events, fetchHrDetails }) {
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const transformedEvents = events.map((event) => ({
    title: event[0],
    start: event[1],
    end: event[2],
  }));

  const eventRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [formData, setFormData] = useState({ title: "", start: "", end: "" });
  const [formPosition, setFormPosition] = useState({ top: "", left: "" });

  const handleDateClick = (arg) => {
    const clickedDateElement = arg.dayEl;

    // Get the class of the clicked date element
    // const clickedDateClass = clickedDateElement.className;
    // clickedDateElement.classList.add('clicked-date');
    // console.log("Class of the clicked date element:", clickedDateClass);

    setFormData({ ...formData, start: arg.dateStr, end: arg.dateStr });

    // Ensure that clickedDateElement has exactly one child element
    if (clickedDateElement.children.length === 1) {
      const childElement = clickedDateElement.children[0];
      // Calculate position relative to clicked date element
      const rect = childElement.getBoundingClientRect();
      const topOffset = rect.top + window.scrollY;
      const leftOffset = rect.left + window.scrollX;
      setFormPosition({ top: topOffset, left: leftOffset });

    }

    setIsVisible(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (new Date(formData.start) > new Date(formData.end)) {
      Swal.fire({
        icon: "error",
        title: "Start date must be less than End Date",
      });
      return;
    }
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey, formData };
      const response = await fetch(`${configData.SERVER_URL}/events`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result);
      if (result.success === true) {
      } else {
        Swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsVisible(false);
      setFormData({ title: "", start: "", end: "" });
      fetchHrDetails();
    }
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (eventRef.current && !eventRef.current.contains(event.target)) {
        setIsVisible(false);
        // Remove 'clicked-date' class from all elements with that class
        const elementsWithClass = document.querySelectorAll(".clicked-date");
        elementsWithClass.forEach((element) => {
          element.classList.remove("clicked-date");
        });
      }
    },
    [setIsVisible]
  );

  useEffect(() => {
    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, handleClickOutside]);

  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        // weekends={false}
        initialView="dayGridWeek"
        // initialView="dayGridMonth"
        events={transformedEvents}
        dateClick={handleDateClick}
      />
      {isVisible && (
        <div
          className="form-container event-form-dashboard"
          ref={eventRef}
          style={{ top: formPosition.top, left: formPosition.left }}
        >
          <form onSubmit={handleSubmit} className="annouce-form">
            <div>
              <label htmlFor="event-id">Title</label>
              <input
                id="event-id"
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label htmlFor="event-start-date">Start Date</label>
              <input
                id="event-start-date"
                type="date"
                name="start"
                value={formData.start}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label htmlFor="event-end-date">End Date</label>
              <input
                id="event-end-date"
                type="date"
                name="end"
                value={formData.end}
                onChange={handleInputChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary mt-2">
              Submit
            </button>
          </form>
        </div>
      )}
      {/* <FullCalendar
        defaultView="dayGridMonth"
        header={{
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay"
        }}
        themeSystem="Simplex"
        plugins={[dayGridPlugin]}
        events={events}
      /> */}
      {/* <FullCalendar
        defaultView="dayGridMonth"
        // themeSystem="Simplex"
        // header={{
        //   left: "prev,next",
        //   center: "title",
        //   right: "dayGridMonth,timeGridWeek,timeGridDay",
        // }}
        plugins={[dayGridPlugin]}
        events={events}
        displayEventEnd="true"
        eventColor={"#" + Math.floor(Math.random() * 16777215).toString(16)}
      /> */}
    </div>
  );
}
