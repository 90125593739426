import React, { createContext, useState } from 'react';

export const ColorContext = createContext();

export const ColorProvider = ({ children }) => {
  const [bgColor, setBgColor] = useState('#ffffff');
  const [textColor, setTextColor] = useState('#ffffff');
  const [pageColor, setPageColor] = useState('#ffffff');
  const [isVisible, setIsVisible] = useState(false);

  return (
    <ColorContext.Provider value={{bgColor, setBgColor , isVisible, setIsVisible ,textColor ,setTextColor ,pageColor,setPageColor}}>
      {children}
    </ColorContext.Provider>
  );
};
