import React, { useState, useContext, useEffect } from "react";
import Calender from "./Calendar/Calendar";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import DailyAttendanceTable from "./DailyAttendanceTable/DailyAttendanceTable";
import "./Home_page.css";
import LoadingComponent from "../loading_component";
import { ColorContext } from "../../ColorContext";
import LeaveTable from "./LeaveTable/LeaveTable";
import { useNavigate } from "react-router-dom";
import configData from "../../../src/config.json";
import Swal from "sweetalert2";
import eventEmitter from "../../Eventemitter"; // Import the event emitter
import { Link } from "react-router-dom";
import BirthdayCarousel from "../Birthday_Carousel/Birthday";

function HomePage({ setSharedFunction }) {
  const navigate = useNavigate();
  const { resultData, fetchData } = useContext(EmplContext);
  const [showTable, setShowTable] = useState(false);
  const [showOne, setShowOne] = useState(true);
  const { bgColor, textColor } = useContext(ColorContext);
  const [empdata, setEmpdata] = useState([]);
  const birthday_record = resultData.birthday_record || [];

  const handleFirst = () => {
    setShowTable(!showTable);
    setShowOne(false);
  };
  const handleSecond = (event) => {
    event.stopPropagation();
    setShowOne(!showOne);
    setShowTable(false);
  };

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-IN",
      options
    );
    return formattedDate;
  };

  const Today = formatDate(new Date());

  const enableFunction = () => {
    setSharedFunction(true);
    navigate("/myattendances");
  };
  const employee_id = resultData.employee_id || 0;

  const Updatedata = async () => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(
        `${configData.SERVER_URL}/updateattendance`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result);
      if (result.success === true) {
        setEmpdata(result);
      } else {
        Swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    Updatedata();
  }, [employee_id]);

  useEffect(() => {
    const handleAttendanceUpdated = () => {
      Updatedata();
    };
    eventEmitter.on("attendanceUpdated", handleAttendanceUpdated);
    return () => {
      eventEmitter.off("attendanceUpdated", handleAttendanceUpdated);
    };
  }, []);

  return (
    <>
      {!bgColor ? (
        <div className="main-panel">
          <LoadingComponent />
        </div>
      ) : (
        <div className={`main-panel ${showTable ? "expanded" : ""}`}>
          <div className="content-wrapper">
            {/* <div className="ep_day">
              <h4>Welcome, {empdata.name}</h4>
            </div> */}
            <div className="ep_content">
              <div className="row">
                <div className="col-md-8">
                  <div className="ep_day">
                    <h4>Welcome, {empdata.name}</h4>
                  </div>

                 
                  <div className="">
                    <div
                      className="ep_ontime"
                      // style={{
                      //   backgroundImage:
                      //     "linear-gradient(to right, #ffdbb8, #ffbf9c, #ffa08b, #ff7e88, #fb5b91)"
                      // }}
                      style={{
                        background: `linear-gradient(90deg, #ffffff, ${textColor})`,
                      }}
                    >
                      <svg className="arrows" onClick={handleFirst}>
                        {showTable ? (
                          <path
                            className="a3"
                            d="M0 52 L12 40 L24 52"
                            role="button"
                          />
                        ) : (
                          <path
                            className="a3"
                            d="M0 40 L12 52 L24 40"
                            role="button"
                          />
                        )}
                      </svg>
                      <div className="ep_tday">
                        <div className="ep_day">Today</div>
                        <div className="ep_date">{Today}</div>
                      </div>
                      {empdata.todays_arrival_time !== null ? (
                        <div className="ep_pday">
                          {empdata.islate_today ? (
                            <div className="ep_ptime">
                              <span>Late</span>
                            </div>
                          ) : (
                            <div className="ep_ptime">
                              <span>Present-on-time</span>
                            </div>
                          )}
                          <div className="ep_etime">
                            <div className="ePtitle">Arrived At </div>
                            <div className="ePtime">
                              {empdata.todays_arrival_time}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {showTable && <DailyAttendanceTable />}
                    <div className="">
                      <div
                        className="ep_ontime"
                        style={{
                          background: `linear-gradient(90deg, #ffffff, ${textColor})`,
                        }}
                        onClick={enableFunction}
                        role="button"
                      >
                        <svg className="arrows" onClick={handleSecond}>
                          {showOne ? (
                            <path className="a3" d="M0 52 L12 40 L24 52" />
                          ) : (
                            <path className="a3" d="M0 40 L12 52 L24 40" />
                          )}
                        </svg>
                        <table>
                          <tbody>
                            <tr>
                              <th
                                rowSpan={2}
                                style={{
                                  color: "#000",
                                  fontSize: 24,
                                  fontWeight: 500,
                                }}
                              >
                                Leave
                              </th>
                              <th>
                                Applied
                                <br />
                                <span style={{ fontSize: 25, fontWeight: 600 }}>
                                  {`${empdata.virtual_taken_leaves || 0}`}
                                </span>
                              </th>
                              <th>
                                Approved
                                <br />
                                <span style={{ fontSize: 25, fontWeight: 600 }}>
                                  {`${empdata.virtual_approved_leaves || 0}`}
                                </span>
                              </th>
                              <th>
                                Balance
                                <br />
                                <span style={{ fontSize: 25, fontWeight: 600 }}>
                                  {`${empdata.virtual_remaining_leaves || 0}`}
                                </span>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {showOne && <LeaveTable />}
                      <div
                        className="d mt-3"
                        style={{
                          boxShadow: "0px 0px 3px #0000002b",
                          borderRadius: 4,
                        }}
                      ></div>
                      <div className="status_leave">
                        <ul>
                          <li>
                            <div className="status1">
                              <div className="icon approved"> </div>
                              <div className="text"> Approved </div>
                            </div>
                          </li>
                          <li>
                            {/* style={{ gap: "20px" }} */}
                            <div className="status1">
                              <div className="icon rejected-al"> </div>
                              <div className="text">Autoleave-Rejected </div>
                            </div>
                          </li>
                          <li>
                            <div className="status1">
                              <div className="icon rejected"> </div>
                              <div className="text"> Rejected </div>
                            </div>
                          </li>
                          <li>
                            <div className="status1">
                              <div className="icon pending"> </div>
                              <div className="text"> Pending </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    
                  </div>

                  <div className="ep_day mt-2 mb-2">
                      <h4>Salary Days</h4>
                    </div>

                    <div>
                      <div
                        className="status_table"
                        role="button"
                      >
                        <table>
                          <tbody>
                            <tr style={{ backgroundColor: textColor }}>
                              <th
                              >
                                Working Days
                              </th>
                              <th>Absent</th>
                              <th>Approved Leaves</th>
                              <th>Unapproved Leaves</th>
                              <th>Salary Days</th>
                            </tr>
                            <tr>
                              <td>{`${empdata.salary_details?.[0] || 0}`}</td>
                              <td>{`${empdata.salary_details?.[1] || 0}`}</td>
                              <td>{`${empdata.salary_details?.[2] || 0}`}</td>
                              <td>{`${empdata.salary_details?.[3] || 0}`}</td>
                              <td>{`${empdata.salary_details?.[4] || 0}`}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                </div>
                <div className="col-md-4">
                  {/* <div className="ep_calender">
            <p>Calender</p>
          </div> */}
                  <div
                    className="cal_header d-flex justify-content-between"
                    style={{
                      padding: "0px 10px",
                    }}
                  >
                    <Link to="/myattendances" className="cal_header_link">
                      <h4>My Attendance</h4>
                    </Link>
                    <Link to="/holidays" className="cal_header_link">
                      <h4>Holidays</h4>
                    </Link>
                  </div>
                  <Calender />

                  {birthday_record.length > 0 && (
                    <>
                  <div
                    className="mt-3"
                    style={{
                      padding: "0px 10px",
                    }}
                  >
                      <h4>Birthdays</h4>
                  </div>
                  <BirthdayCarousel birthday_record={birthday_record}/>
                    </>
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HomePage;
