import React, { useContext, useEffect, useState } from "react";
import "./Manual-Attendance.css";
import { ColorContext } from "../../ColorContext";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../../src/config.json";
import Swal from "sweetalert2";
import {
  FaArrowRightToBracket,
  FaArrowRightFromBracket,
} from "react-icons/fa6";
import eventEmitter from "../../Eventemitter"; // Import the event emitter

function ManualAttendance() {
  const { resultData, fetchData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [is_present_already, setIs_present_already] = useState(false);
  const { textColor } = useContext(ColorContext);

  const UpdateAttendance = async (is_checkin) => {
    try {
      const currenttime = new Date();
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey, currenttime, is_checkin };
      const response = await fetch(
        `${configData.SERVER_URL}/setmanualattendance`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result);
      if (result.success === true) {
        setIs_present_already(result.is_present_already);
        const messageText = is_checkin
          ? is_present_already
            ? `Checked out at ${currenttime.toLocaleTimeString()}`
            : `Checked in at ${currenttime.toLocaleTimeString()}`
          : false;
        if (messageText) {
          Swal.fire({
            icon: "success",
            title: messageText,
          });
        }
        eventEmitter.emit("attendanceUpdated"); // Emit the event
      } else {
        Swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    UpdateAttendance(false);
  }, [resultData]);

  return (
    <>
      <div>
        <div className="content-manual-attendance d-none d-lg-block">
          {!is_present_already ? (
            <>
              <span
                className="arrow-btn check-inbtn"
                role="button"
                onClick={() => UpdateAttendance(true)}
                style={{ backgroundColor: textColor }}
              >
                Check-in
                <FaArrowRightToBracket />
              </span>
            </>
          ) : (
            <>
              <span
                className="arrow-btn check-outbtn"
                role="button"
                onClick={() => UpdateAttendance(true)}
                style={{ backgroundColor: textColor }}
              >
                Check-out
                <FaArrowRightFromBracket />
              </span>
            </>
          )}
        </div>
        <div className="content-manual-attendance d-block d-lg-none">
          {!is_present_already ? (
            <>
              <span
                className="arrow-btn check-inbtn"
                role="button"
                onClick={() => UpdateAttendance(true)}
                style={{ backgroundColor: textColor }}
              >
                <FaArrowRightToBracket />
              </span>
            </>
          ) : (
            <>
              <span
                className="arrow-btn check-outbtn"
                role="button"
                onClick={() => UpdateAttendance(true)}
                style={{ backgroundColor: textColor }}
              >
                <FaArrowRightFromBracket />
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ManualAttendance;
