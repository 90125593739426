import React, { useContext, useState } from "react";
import "./Customize.css";
import { ColorContext } from "../../ColorContext";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../../src/config.json";
import { MuiColorInput } from "mui-color-input";
import Swal from "sweetalert2";

function Customize() {
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [newset, setNewSet] = useState('')
  const [picker, setPicker] = useState('#fff')
  const { setBgColor, setTextColor, setPageColor, setIsVisible } =
    useContext(ColorContext);
  const hideCustomization = () => {
    setIsVisible(false);
  };
  const handleColorChange = (value) => {
    setPicker(value);
  };
  const handleColorClick = (color) => {
    // setBgColor(color);
    setBgColor(color.split(",")[0]);
    setTextColor(color.split(",")[1]);
    setPageColor(color.split(",")[2]);
    setNewSet(color);
  };
  const ChangeTheme = async () => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey, newset };
      const response = await fetch(`${configData.SERVER_URL}/settheme`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result);
      if (result.success === true) {
        hideCustomization();
      } else {
        Swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {/* <div className="d-flex">
        <div className="vr"></div>
      </div> */}
      <div className="visible-div">
        {/* <span>Customize the theme</span> */}
        <div className="color-palette row-1">
          <div
            className="color-option column-1"
            onClick={() => handleColorClick("#D3E3FD,#0B57D0,#e0eaff72")}
          >
            <div className="half-circle upper-half"></div>
            <div className="quarter-circle bottom-left"></div>
            <div className="quarter-circle bottom-right"></div>
          </div>

          <div
            className="color-option column-2"
            onClick={() => handleColorClick("#bbf1a9,#3b6930,#a0d49033")}
          >
            <div className="half-circle upper-half"></div>
            <div className="quarter-circle bottom-left"></div>
            <div className="quarter-circle bottom-right"></div>
          </div>
          <div
            className="color-option column-3"
            onClick={() => handleColorClick("#fce27c,#6f5d00,#dec66333")}
          >
            <div className="half-circle upper-half"></div>
            <div className="quarter-circle bottom-left"></div>
            <div className="quarter-circle bottom-right"></div>
          </div>
          <div
            className="color-option column-4"
            onClick={() => handleColorClick("#fbdccc,#705a4d,#dec1b133")}
          >
            <div className="half-circle upper-half"></div>
            <div className="quarter-circle bottom-left"></div>
            <div className="quarter-circle bottom-right"></div>
          </div>
        </div>
        <div className="color-palette row-2">
          <div
            className="color-option column-1"
            onClick={() => handleColorClick("#dae6d3,#566253,#becab833")}
          >
            <div className="half-circle upper-half"></div>
            <div className="quarter-circle bottom-left"></div>
            <div className="quarter-circle bottom-right"></div>
          </div>

          <div
            className="color-option column-2"
            onClick={() => handleColorClick("#ebdcff,#6a5294,#d4bbff33")}
          >
            <div className="half-circle upper-half"></div>
            <div className="quarter-circle bottom-left"></div>
            <div className="quarter-circle bottom-right"></div>
          </div>
          <div
            className="color-option column-3"
            onClick={() => handleColorClick("#d4e6e9,#516164,#b8cacd33")}
          >
            <div className="half-circle upper-half"></div>
            <div className="quarter-circle bottom-left"></div>
            <div className="quarter-circle bottom-right"></div>
          </div>
          <div
            className="color-option column-4"
            onClick={() => handleColorClick("#ffd9df,#924759,#ffb1c033")}
          >
            <div className="half-circle upper-half"></div>
            <div className="quarter-circle bottom-left"></div>
            <div className="quarter-circle bottom-right"></div>
          </div>
        </div>
        <div className="color-palette">
          {/* <label htmlFor="customize-color" className="mx-2">
            Select color:-
          </label>
          <MuiColorInput
            id="customize-color"
            value={picker}
            format="hex"
            isAlphaHidden
            onChange={handleColorChange}
            style={{ width: "14%" }}
          /> */}
        </div>

        <button
          className="btn btn-primary mt-2"
          type="button"
          onClick={ChangeTheme}
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          Save
        </button>
        <button
          className="btn btn-secondary mt-2"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          cancel
        </button>
      </div>
    </>
  );
}

export default Customize;
