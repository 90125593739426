import React, { useContext, useState, useEffect, useMemo } from "react";
import { EmplContext } from "../../Login_Page/Login_Page/LoginPage";
import "./DailyAttendanceTable.css";
import { ColorContext } from "../../../ColorContext";

const DailyAttendanceTable = () => {
  const { resultData } = useContext(EmplContext);
  const [expectedhours, setExpectedhours] = useState("");
  const [proHours, setProhours] = useState("");

  const { bgColor ,textColor} = useContext(ColorContext);

  const todays_machine_attendances = useMemo(
    () => resultData.todays_machine_attendances || [],
    [resultData.todays_machine_attendances]
  );
  const current_diff = resultData.current_diff || 0.0;

  useEffect(() => {
    let totalCount = 0;
    let proHours = 0;
    todays_machine_attendances.forEach((innerArray) => {
      const floatVal = parseFloat(innerArray[2]);
      const proHoursVal = parseFloat(innerArray[3]);
      totalCount += floatVal;
      proHours += proHoursVal;
    });
    // const expectedH = current_diff - totalCount;
    setExpectedhours(totalCount);
    setProhours(proHours);
  }, [todays_machine_attendances, current_diff]);

  const convertDecimalToHHMMSS = (decimalHours) => {
    const totalSeconds = Math.floor(decimalHours * 3600);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Pad the hours, minutes, and seconds with leading zeros if they are less than 10
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  // console.log("expectedhours=================",expectedhours);

  return (
    <div className="status_table">
      <table className="status_table">
        <thead>
          <tr style={{ background: textColor }}>
            <th>Serial.no</th>
            <th>Check-In</th>
            <th>Check-Out</th>
            <th>Out of office</th>
            <th>Hours</th>
          </tr>
        </thead>
        <tbody>
          {todays_machine_attendances.map((attendance, index) => (
            <tr key={index}>
              <td> {index + 1}</td>
              <td>{attendance[0]}</td>
              <td>{attendance[1]}</td>
              <td>{convertDecimalToHHMMSS(attendance[2])}</td>
              <td>{convertDecimalToHHMMSS(attendance[3])}</td>
            </tr>
          ))}
          {current_diff !== 0 && (
            <tr className="bg-productive">
              <td className="bg-productive-first" colSpan={5}>
                <div className="bg-productive-heading">
                  <span>Total Hours</span>
                  <span>{convertDecimalToHHMMSS(current_diff)}</span>
                </div>
                <div className="bg-productive-heading">
                  <span>Out of Office</span>
                  <span>{convertDecimalToHHMMSS(expectedhours)}</span>
                </div>
                <div className="bg-productive-heading">
                  <span>Total Productive Hours</span>
                  <span>{convertDecimalToHHMMSS(proHours)}</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DailyAttendanceTable;
